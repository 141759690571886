.nav_top[data-v-3465dbb4] {
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  z-index: 2000;
  background-color: #f7f9fc;
}
.main[data-v-3465dbb4] {
  width: 100%;
  height: calc(100vh);
  background-color: #f7f9fc;
  color: #68696b;
}
.main .main-card[data-v-3465dbb4] {
    -webkit-box-sizing: border-box;
            box-sizing: border-box;
    padding-top: 40px;
    height: 100%;
    background-color: white;
    -webkit-box-shadow: 2px 4px 12px #edeeef !important;
            box-shadow: 2px 4px 12px #edeeef !important;
    overflow: auto;
}
.main .main-card .deal-content[data-v-3465dbb4] {
      overflow-y: hidden;
      background-color: #fff;
      width: 100%;
      height: 100%;
      display: -webkit-box;
      display: -ms-flexbox;
      display: flex;
      -webkit-box-orient: vertical;
      -webkit-box-direction: normal;
          -ms-flex-direction: column;
              flex-direction: column;
      -webkit-box-align: center;
          -ms-flex-align: center;
              align-items: center;
}
.main .main-card .deal-content .flow-title[data-v-3465dbb4] {
        width: 100%;
        text-align: center;
        line-height: 40px;
        height: 40px;
}
.main .main-card .deal-content .flow-title .urgent[data-v-3465dbb4] {
          position: absolute;
          right: 0;
          margin-right: 30px;
          color: red;
          font-weight: 700;
}
.main .main-card .deal-content .flow-title .urgent .el-checkbox[data-v-3465dbb4] {
            margin-right: 10px;
}
.main .main-card .deal-content .deal-form[data-v-3465dbb4] {
        width: 100%;
        height: calc(100% - 60px);
        overflow-y: auto;
}
.main .main-card .deal-content .deal-btns[data-v-3465dbb4] {
        position: fixed;
        bottom: 0;
        margin-top: 10px;
        width: 100%;
        height: 50px !important;
        line-height: 50px !important;
        text-align: center;
        background-color: #fff;
        border: 1px solid transparent;
}
.main .main-card .deal-content .deal-btns .deal-btn-item[data-v-3465dbb4] {
          height: 32px;
          padding: 4px 14px;
          margin-left: 20px;
}
.main .main-card .deal-content .deal-tools[data-v-3465dbb4] {
        position: absolute;
        bottom: 39px;
        left: 50px;
        z-index: 2;
        padding: 10px 0;
}
.main .main-card .deal-content .deal-flow[data-v-3465dbb4] {
        width: 100%;
        height: 100%;
        outline: none;
}
.main .main-card .deal-logs[data-v-3465dbb4] {
      color: black;
      width: 100%;
      height: 100%;
      padding: 10px 14px;
      -webkit-box-sizing: border-box;
              box-sizing: border-box;
      background-color: #fff;
      border-radius: 3px;
}
.main .main-header[data-v-3465dbb4] {
    height: 9.25926vh;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
}
.main .main-content[data-v-3465dbb4] {
    height: 100%;
    margin-left: 10px;
    margin-right: 10px;
    position: relative;
}
.main .main-content .custom-tabs[data-v-3465dbb4] .is-active {
      background-color: #fff;
}
.main .main-content .custom-tabs[data-v-3465dbb4] .el-tabs__item {
      padding: 0 3.333vw;
      font-size: 20px;
      font-weight: 550;
}
.main .main-content .custom-tabs[data-v-3465dbb4] .el-tabs__nav {
      -webkit-box-sizing: content-box;
              box-sizing: content-box;
      border-width: 2px;
}
.main .main-content .custom-tabs[data-v-3465dbb4] .el-tabs__header {
      border-width: 2px;
      margin: 0;
}
.main .main-content .top-button[data-v-3465dbb4] {
      position: absolute;
      right: 10px;
      z-index: 2;
      font-weight: bold;
      cursor: pointer;
      color: #000;
      border-radius: 4px;
}
.main .main-content .top-button[data-v-3465dbb4]:hover {
      color: #409eff;
      fill: #409eff;
}
